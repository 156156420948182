<template>
  <div class="mainboxs">
    <div :class="whichSchoolId == 8 ? 'mainbox' : whichSchoolId == 9 ? ' mainbox mainbox1' : 'mainbox mainbox2'">
      <div class="news_til">
        <div class="news_til_left">
          <img src="~@/assets/img/TechnicalCollege/98.png" alt="">{{ schoolName }}推荐学校
        </div>
        <div @click="goNext('/gxGather/graduate/academy')" class="news_til_right">进入{{ schoolName }}频道页
          <img src="~@/assets/img/TechnicalCollege/24.png" alt="">
        </div>

      </div>
      <div class="academy_list">
        <div class="academy_lists">
          <div v-for="(item, index) in majorList" :key="index" class="academy_item">
            <div class="academy_item_logo">
              <img :src="item.schoolBadge" alt="">

            </div>
            <div class="academy_item_detail">
              <div style="max-width: 250px;" class="school_name elp">{{ item.schoolName }}</div>
              <div style="max-width: 250px;" class="school_major elp"><span>招生专业：</span>{{ item.majorName }}</div>
              <div class="school_msg">
                <div>招生简章</div>
                <div>常见问题</div>
                <div>报考咨询</div>
              </div>
            </div>
            <div @click="goNext(`/gxGathers/index?id=${item.id}`)" class="btn">查看学校</div>
            <div v-if="!(index % 2)" class="line"></div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
  components: {},
  props: {
    whichSchoolId: {
      type: String,
    },
    schoolName: {
      type: String,
    },

  },
  data() {
    return {
      whichNews: 1,
      sImg: '',
      link: '',
      majorList: [],
      newsList: [],
      search: {
        pageSize: 11,
        pageNum: 1,
        projectTypeId: ''
      },
    };
  },
  created() {
    console.log(this.whichSchoolId, 'whichSchoolId');
    this.projectType = localStorage.getItem('projectType');
    this.search.projectTypeId = localStorage.getItem('projectTypeId');

    this.getStudyAbroadSchoolPage()

  },
  mounted() {

  },
  methods: {
    chooseMajor(i) {
      this.whichNews = i
    },
    getStudyAbroadSchoolPage() {
      know.selectGraduateSchoolPage({
        pageNum: 1,
        pageSize: 8,
        projectCategoryId: this.whichSchoolId,
      })
        .then((res) => {
          this.majorList = res.rows
        });
    },

    goPointLink() {
      if (this.link) {
        window.open(this.link, '_blank');

      }
    }

  },
};
</script>

<style lang="less" scoped>
.mainboxs {
  background: #F5F7F9;
  padding: 20px 0;
  padding-bottom: 0;
}

.mainbox {
  width: 1200px;
  height: 544px;
  background-image: url('~@/assets/img/TechnicalCollege/97.png');
  background-size: 100% 100%;
  margin: 0 auto;
  padding: 18px;

  .news_til {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .news_til_left {
      display: flex;
      align-items: center;

      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
      font-weight: bold;
      font-size: 24px;
      color: #FFFFFF;

      img {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }
    }

    .news_til_right {
      display: flex;
      align-items: center;
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: normal;
      font-size: 14px;
      color: #053DA9;
      cursor: pointer;

      img {
        width: 12px;
        height: 12px;
        margin-left: 10px;
      }
    }

  }

  .academy_list {
    padding: 30px;
    width: 1162px;
    height: 460px;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    margin-top: 16px;

    margin-bottom: 20px;

    .academy_lists {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .academy_item {
      display: flex;
      align-items: center;
      margin-bottom: 60px;

      .academy_item_logo {
        width: 95px;
        height: 95px;
        border-radius: 0px 0px 0px 0px;


      }

      .academy_item_detail {
        margin-left: 26px;

        .school_name {
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          font-size: 18px;

        }

        .school_major {
          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: normal;
          font-size: 12px;
          color: #333333;
          margin-top: 12px;

          span {

            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: normal;
            font-size: 14px;
            color: #888888;
          }
        }

        .school_msg {
          display: flex;
          justify-content: space-between;

          >div {
            width: 74px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: normal;
            font-size: 12px;
            margin-right: 10px;
            margin-top: 10px;
          }

          >div:nth-child(1) {
            background: url('~@/assets/img/TechnicalCollege/53.png');
            background-size: 100% 100%;
            color: #3D6CC8;

          }

          >div:nth-child(2) {
            background: url('~@/assets/img/TechnicalCollege/54.png');
            background-size: 100% 100%;
            color: #DF932F;

          }

          >div:nth-child(3) {
            background: url('~@/assets/img/TechnicalCollege/55.png');
            background-size: 100% 100%;
            color: #BC3535;

          }
        }
      }

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 96px;
        height: 40px;
        border-radius: 20px 20px 20px 20px;
        border: 1px solid #3D6CC8;
        cursor: pointer;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: normal;
        font-size: 14px;
        color: #3D6CC8;
        margin-left: 50px;

        &:hover {
          background: #3D6CC8;
          color: #FFFFFF;
        }
      }

      .line {
        width: 0px;
        height: 64px;
        border: 1px solid #D6D5E6;
        margin-left: 31px;
      }

      &:nth-child(2n) {
        margin-left: 30px;
      }

      &:hover {
        .school_name {
          color: #3D6CC8;
        }
      }
    }
  }



}

.mainbox1 {
  background-image: url('~@/assets/img/TechnicalCollege/122.png');
  background-size: 100% 100%;
}

.mainbox2 {
  background-image: url('~@/assets/img/TechnicalCollege/121.png');
  background-size: 100% 100%;
}

img {
  display: block;
  width: 100%;
  height: 100%;
}
</style>