<template>
    <div class="certificateView">
        <div style="width: 100%;">
            <img src="~@/assets/img/TechnicalCollege/43.png" alt="">
        </div>
        <news :id="id" class="banner" />
        <div class="country countrys">
            <div class="country_detail">
                <div class="country_til">
                    <img style="width: 40px;margin-right: 8px;" src="~@/assets/img/TechnicalCollege/96.png" alt="">
                    在职研究生 <span>学校推荐</span>
                    <div class="b_bg">CURRICULUM SYSTEM</div>
                </div>
                <academy v-for="(item, index) in mList" :key="index" :whichSchoolId="item.id"
                    :schoolName="item.typeName" class="banner" />
            </div>
        </div>
    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
// import banners from "./banner.vue";
import news from "./news.vue";
// import major from "./major.vue";
import academy from "./academy.vue";

export default {
    components: { news, academy },

    data() {
        return {
            id: '',
            countryList: [],
            mList: [],
        };
    },
    created() {

        this.id = localStorage.getItem('projectTypeId');
        know.selectProjectTypePullList({ parentId: this.id }).then((res) => {
            this.mList = res.data;
        });
        know.getStudyCountryPage({ pageSize: 4, pageNum: 1 }).then((res) => {
            this.countryList = res.rows
        });
    },
    mounted() { },
    methods: {

    },
};
</script>

<style lang="less" scoped>
img {
    width: 100%;
}

.country {
    background: #E8E9EF;
    padding: 30px 0;

    .country_detail {
        width: 1200px;
        margin: 0 auto;

        .country_til {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;

            font-size: 34px;
            color: #333333;

            span {
                color: #36487A;

            }

            .b_bg {
                position: absolute;
                font-family: Arial-Black, Arial-Black;
                font-weight: normal;
                font-size: 29px;
                color: #A094C3;
                bottom: -5PX;
                opacity: 0.11;
            }
        }

        .country_con {
            display: flex;

            .country_con_item {
                position: relative;
                border-radius: 8px 8px 8px 8px;
                // background: rgba(255, 255, 255, 0.5);

                margin-top: 30px;
                margin-right: 20px;

                img {
                    // position: absolute;
                    width: 285px;
                    border-radius: 8px 8px 8px 8px;
                    height: 173px;
                }

                .country_con_items {
                    position: absolute;
                    width: 285px;
                    height: 173px;
                    background: rgba(0, 0, 0, 0.5);
                    border-radius: 8px 8px 8px 8px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .country_con_items_til {
                        position: relative;
                        font-family: PingFangSC-Medium, PingFangSC-Medium;
                        font-weight: normal;
                        font-size: 20px;
                        color: #FFFFFF;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .line {
                            position: absolute;
                            bottom: 0;
                            z-index: 9;
                            width: 85px;
                            height: 10px;
                            background: linear-gradient(90deg, #705AD5 0%, #FFC4AF 100%);
                            border-radius: 0px 0px 0px 0px;
                        }

                    }

                    .item_con {
                        width: 222px;
                        font-family: PingFangSC-Regular, PingFangSC-Regular;
                        font-weight: normal;
                        font-size: 14px;
                        color: #FFFFFF;
                        text-align: center;
                        margin-top: 20px;
                    }

                }
            }

        }

    }
}

.countrys {
    background: #F5F7F9;
}
</style>